<template>
    <list-page
            :columns="columns"
            :headers="headers"
            sort="id"
            title="Link List"
            icon="mdi-link"
            stateendpoint="links.allowedLinks"
            :show-default-action-buttons="false"
            :show-add-button="false"
            :actions="actions"
    />
</template>

<script>
    import ListPage from "../../components/ListPage";
    export default {
        name: "links",
        metaInfo: {
            title: 'webexpressmedia.com',
            titleTemplate: 'Admin Dashboard - Link List | %s',
            meta: [
                { name: 'robots', content: 'noindex' },
                { name: 'googlebot', content: 'noindex' }
            ]
        },
        components: {
            ListPage
        },
        data() {
            return {
                headers: [
                    {
                        text: 'Site ID',
                        value: 'siteId'
                    },
                    {
                        text: 'Site URL',
                        value: 'siteUrl'
                    },
                    {
                        text: 'Live Link',
                        value: 'liveLink'
                    },
                    {
                        text: 'Is Allowed Link Insert?',
                        value: 'status'
                    }
                ],
                columns: [
                    {value: 'siteId'},
                    {value: 'siteUrl'},
                    {value: 'liveLink'},
                    {value: 'isActive'}
                ],
                actions: {
                    load: 'loadLinks',
                    update: 'updateLinks'
                }
            }
        }
    }
</script>

<style scoped>

</style>